import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch} from "react-redux";
import useAuthLookup from "../../../../../../HOOK/useAuthLookup";
import StepLookup from "./steps/StepLookup";
import StepUserData from "./steps/StepUserData";
import {useFormikContextProviderRegister} from "../../../../SignUp";
import StepRegisterBot from "./steps/StepRegisterBot";
import IconArrow from "../../../../../../Icons/IconArrow";
import {useLang} from "../../../../../../context/langProvider";

const STEPS_COUNT = {
    email: 3,
    phone: 3,
}
const RegistrationUser = ({onHandlerSwitch, project_rules_name, setting_phone_code_country=null}) => {
    const {getT} = useLang();
    const [activeStep, setActiveStep] = useState(1);
    const [stepCount, setStepCount] = useState(1);
    const {values} = useFormikContextProviderRegister()
    const {
        available_message_channel_list, full_message_channel_list,
    } = useAuthLookup();
    const stepRegister = useMemo(() => {
        let lookup = values.phone && !values.email ? 'phone' : 'email';
        let steps_switch = {
            email: {
                'step_1': 1,
                'step_2': 2,
                'step_3': 3,
                'step_4': 4,
            },
            phone: {
                'step_1': 1,
                'step_2': 2,
                'step_3': 3,
                'step_4': 4,
            }
        }
        return {
            step_amount: STEPS_COUNT[lookup],
            steps: steps_switch[lookup]

        }
    }, [values]);
    const handleNext = (step = null) => {
        let steps = stepRegister.steps;
        let next_count = stepCount + 1
        let next_value_step = steps[`step_${next_count}`];
        if (next_value_step >= 6) return
        setActiveStep((prevStep) => next_value_step);
        setStepCount((prevStep) => next_count);

    };
    const handlerPrev = () => {
        let steps = stepRegister.steps;
        let current = steps[`step_${stepCount}`];
        let prev = steps[`step_${stepCount - 1}`];
        if (current === 1 || prev < 0) return
        setActiveStep((prevStep) => prev);
        setStepCount(prev);

    }


    const getStepComponent = () => {
        switch (activeStep) {
            case 1:
                return <StepLookup onSwitchTab={onHandlerSwitch} handleNext={handleNext} setting_phone_code_country={setting_phone_code_country}/>;
            case 2:
                return <StepUserData handleNext={handleNext}/>;
            case 3:
                return <StepRegisterBot handleNext={handleNext} full_message_channel_list={full_message_channel_list}
                                        available_message_channel_list={available_message_channel_list}/>;

            default:
                return null;
        }
    };
    return (
        <div>
            <h2 className={'form-title d-flex items-center justify-center'}>
                {stepCount !== 1 &&
                    <span className="custom-btn-prev d-flex items-center" onClick={handlerPrev}>  <IconArrow
                        style={{transform: "none", marginRight: "8px"}}/></span>}
                {getT('Регистрация')} {project_rules_name ?? ''} </h2>
            <div className={'form-sm card-form'} style={{borderWidth: `${activeStep == 3 ? '0': '1px'}`}} role="presentation">

                {getStepComponent()}
            </div>
        </div>
    );
};

export default RegistrationUser;