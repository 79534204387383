import React from 'react';
import MainApi from './MainApi';
import {LS_IMG_API_URL} from "../constants";

class ApiImages extends MainApi {

    //TODO: ссылка под замену (АПИ)

    constructor() {
        super();
        this._urlDefApi = process.env.REACT_APP_API_PART_PREVIEWS_SVG;

        let hostname = window.location.hostname,
            delimiter = hostname.match('-'),
            protocol = window.location.protocol;
        if (localStorage.getItem(LS_IMG_API_URL) !== 'normal' && !!(localStorage.getItem(LS_IMG_API_URL))) {
            this._baseUrl = localStorage.getItem(LS_IMG_API_URL);
        } else {
            this._baseUrl = this._urlDefApi;
            // if (delimiter) {
            //     if (delimiter.input.match(/(\d+)/g)) {
            //         this._baseUrl = protocol + "//" + hostname.substr(0, delimiter.index) + "-" + this._urlDefApi;
            //     } else if (hostname.substr(0, delimiter.index) === 'local') {
            //         this._baseUrl = protocol + '//' + this._urlDefApi;
            //         // this._baseUrl = 'http://local-img.ifurn.pro/api/';
            //     } else {
            //         this._baseUrl = protocol + "//" + hostname.substr(0, delimiter.index) + "-" + this._urlDefApi;
            //     }
            // } else {
            //     if (hostname.includes("localhost") || hostname.match(/(\d+)/g)) {
            //         this._baseUrl = 'https://' + this._urlDefApi;
            //     } else {
            //         this._baseUrl = protocol + "//" + this._urlDefApi;
            //     }
            // }
        }
    }

    // _baseUrl = 'https://img.ifurn.pro/api';
    // _baseUrl = 'http://img-local.ifurn.pro/api';
    // _baseUrl = 'https://i.ifurn.pro/api';
    // _baseUrl = 'https://newgo.ifurn.pro/api';


    // async _send(url, method, data, headers = {}, resType = 'json') {
    //
    //   let body = null;
    //   let options = {
    //     method: method,
    //     mode: 'cors',
    //     cache: 'no-cache',
    //     credentials: 'same-origin',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'Language': localStorage.getItem('ifurn_store_main_language'),
    //       'User-Token': localStorage.getItem('ifurn_service_main_user_token'),
    //       'X-Api-Key': this._apiKey,
    //       ...headers
    //     },
    //     redirect: 'follow',
    //     referrerPolicy: 'no-referrer',
    //   }
    //   if (method === 'POST' || method === 'PUT') {
    //     options.body = JSON.stringify(data);
    //   }
    //   const res = await fetch(`${this._baseUrl}${url}`, options);
    //
    //   try {
    //     if (resType === 'json') {
    //       body = await res.json();
    //     }
    //     if (resType === 'text') {
    //       body = await res.text();
    //     }
    //   } catch (e) {
    //     alert(e);
    //     body = false;
    //   }
    //
    //   if(body.error_api) {
    //     this._errors = body.error_api;
    //     return false;
    //   }
    //
    //   return body;
    // }

    getPartsSmallPreviews = async (order) => {

        if (!order) return false;

        const res = await this._send(
            `/images/`,
            'POST',
            {
                json: {
                    order: order
                },
                view: {
                    path: 4
                },
                action: 'svg'
            },
            {},
            'json'
        );
        if (res && res.image && Array.isArray(res.image)) {
            return res.image;
        } else {
            return false;
        }
    }
    getCuttingPreviews = async (order) => {

        if (!order) return false;

        const res = await this._send(
            `/images/`,
            'POST',
            {
                json: {
                    order: order
                },
                view: {
                    path: 4
                },
                action: 'svg_cart'
            },
            {},
            'json'
        );
        if (res && res.image && Array.isArray(res.image)) {
            return res.image;
        } else {
            return false;
        }
    }

    getPartImage = async (order, part_id) => {

        if (!order || !part_id) return false;
        const res = await this._send(
            `/images/`,
            'POST',
            {
                view: {
                    part: part_id,
                    zoom: '',
                    scrollx: '',
                    scrolly: '',
                    jpeg: 0,
                    jpegx: '',
                    jpegy: '',
                    png: 0,
                    pngx: '',
                    pngy: '',
                    html: true,
                    new_face: 1
                },
                json: {
                    order: order,
                    monitor_size: {
                        y: Number(window.screen.height) - 200,
                        x: Number(window.screen.width) - 200
                    }
                },

            },
            {},
            'json'
        );
        if (res && res.image) {
            return res.image;
        } else {
            return false;
        }
    }


}

export default ApiImages;