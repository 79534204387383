import React, {createContext, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getRuleSettingsProject} from "../../selectors/common-selector";
import Header from "./component/Header";
import "./main.css"
import {useLang} from "../../context/langProvider";
import useAuthLookup from "../../HOOK/useAuthLookup";
import {useFormik} from "formik";
import {parsePhoneNumber, validatePhoneNumber} from "../../helpers/helpersListCountryCode";
import {authLookupAC} from "../../store/actions/auth-actions";
import LoginEmail from "./component/form/sign_in/LoginEmail";
import LoginPhone from "./component/form/sign_in/LoginPhone";
import {authThunkAccountAccess, authThunkLogin, getAuthDataThunk} from "../../store/reducers/AuthReducer";
import {getAuthUserFirms} from "../../selectors/auth-selector";
import useSeconds from "../../HOOK/useSeconds";
import {useHistory} from "react-router-dom";
import {onSetFirmDT} from "../../store/reducers/RegisterReducer";
import {FacebookLoginButton, GoogleLoginButton} from "react-social-login-buttons";
import {LoginSocialFacebook, LoginSocialGoogle} from "reactjs-social-login";
import SocialLogin from "./component/form/sign_in/SocialLogin";

// Create a context for Formik
const FormikContext = createContext();

// Custom hook to use Formik context
export const useFormikContextProviderLogin = () => {
    return useContext(FormikContext);
};


const SignIn = () => {
    const {getT} = useLang();
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {

        if (window?.history?.state?.state?.firm_code) {
            dispatch(onSetFirmDT(window?.history?.state?.state?.firm_code));
        }
    }, [])
    const auth_user_firms_state = useSelector(getAuthUserFirms);

    const project_rules = useSelector(getRuleSettingsProject);
    const {auth_lookup, isAuthFind, isSendShortPassword} = useAuthLookup();
    const [seconds, isButtonDisabled, handlerStartSeconds] = useSeconds()

    const formik = useFormik({
        initialValues: {
            password: '',
            master_password: '',
            message_channel: '',
            email: '',
            phone: '',
            //**авторизация по email = true or  phone=false */
            isRadioLoginEmail: true,
            //** тип пароля короткий,мастер */
            isRadioShortPassword: true,
            //** редактировать поле email or phone */
            isEditEmailOrPhone: true,
        },
        validate: (values) => {
            const errors = {};

            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if ((!values.email || !emailRegex.test(values.email)) && values.isRadioLoginEmail) {
                errors['email'] = getT("Email-адрес некорректен.");
            }
            const {countryCode, phoneNumberWithoutCode, mask} = parsePhoneNumber(values['phone']);
            const isValidPhone = validatePhoneNumber(countryCode, phoneNumberWithoutCode);
            if (!isValidPhone && !values.isRadioLoginEmail) {
                errors['phone'] = getT("Некоректний номер телефону");
            }
            return errors;
        },
        onSubmit: (values) => {
            let auth = {
                email: values.isRadioLoginEmail ? values.email : values.phone,
                password: isAuthFind && !isSendShortPassword ? values.master_password : values.isRadioShortPassword ? values.password : values.master_password
            }

            dispatch(authThunkLogin(auth))
        }

    });
    const handlerSwitchLogin = (isLoginType) => {
        dispatch(authLookupAC(null));
        handlerStartSeconds(false);
        formik.resetForm();
        formik.setFieldValue('isRadioLoginEmail', isLoginType)
    }
    const onReqAccountAccess = ({send_password = 0, message_channel = null}) => {
        if (send_password) {
            handlerStartSeconds(true);
        }
        let login = formik.values.isRadioLoginEmail ? formik.values.email : formik.values.phone;
        //** если пользователя нету переключаем таб на регестрацию*/
        dispatch(authThunkAccountAccess({
            login: login,
            send_password: send_password, message_channel: message_channel
        }));

    }
    useEffect(() => {
        if (isAuthFind && !isSendShortPassword) {
            handlerStartSeconds(false);
        }
    }, [isSendShortPassword, isAuthFind])

    //** если пользователь найден по мылу или телефону*/
    useEffect(() => {
        if (isAuthFind) {
            formik.setFieldValue('isEditEmailOrPhone', false)
        }
    }, [isAuthFind, auth_lookup])
    const onHandlerEdit = () => {
        dispatch(authLookupAC(null))
        formik.setFieldValue('isEditEmailOrPhone', true);
        handlerStartSeconds(false)
    }

    return (
        <div className=" main-login-page-container">
            <Header project_rules={project_rules} firm_code={history?.location?.state?.firm_code}>


            </Header>
            <h2 className={'form-title'}>{!auth_user_firms_state?.length ? `${getT('Авторизоваться')} ${project_rules?.name ?? ''}` : getT('Выберите компанию из списка')}</h2>
            <div className={'form-sm card-form'} role="presentation">
                <FormikContext.Provider value={formik} autoComplete={"off"}>
                    <div className="choice-type-login d-flex flex-row">
                        <div className="item d-flex align-items-center">
                            <input type="radio" value="1" id={'email'} checked={formik.values.isRadioLoginEmail}
                                   className={'flex-auto'}
                                   onChange={() => handlerSwitchLogin(true)}
                            />
                            <label htmlFor="email">
                                {getT('Почта')}
                            </label>
                        </div>
                        <div className="item d-flex align-items-center">
                            <input type="radio" value="0" id={'tel'} checked={!formik.values.isRadioLoginEmail}
                                   className={'flex-auto'}
                                   onChange={() =>
                                       handlerSwitchLogin(false)}
                            />
                            <label htmlFor="tel">
                                {getT('Телефон')}
                            </label>
                        </div>

                    </div>
                    <form onSubmit={(e) => e.preventDefault()} autoComplete={'off'}>
                        <LoginEmail onReqAccountAccess={onReqAccountAccess} onHandlerEdit={onHandlerEdit}
                                    seconds={seconds}
                                    isButtonDisabled={isButtonDisabled}/>
                        <LoginPhone onReqAccountAccess={onReqAccountAccess} onHandlerEdit={onHandlerEdit}
                                    seconds={seconds}
                                    isButtonDisabled={isButtonDisabled}
                                    setting_phone_code_country={project_rules?.phoneCode}/>
                    </form>
                </FormikContext.Provider>
                <SocialLogin/>
            </div>
        </div>
    );
};

export default SignIn;