import {getDetailOptionSizesOfType} from "./helpers";


function trimToFiveDecimals(number) {
    // Check if the input is a finite number
    if (Number.isFinite(number)) {
        // Round the number to 5 decimal places
        return parseFloat(number.toFixed(5));
    } else {
        // Return the original value if the input is not a number
        return number;
    }
}

export const getGoodsServicePartCountCalculateWithType = (goods_service, part, sizeType, product_count = 1) => {
    let keys_part_size = getDetailOptionSizesOfType(sizeType)
    let is_perimetr = !!Number(goods_service.count_connected_to_perimetr)
    let is_volume = !!Number(goods_service.count_connected_to_volume)
    let is_square = !!Number(goods_service.count_connected_to_square);
    let part_x = Number(part[keys_part_size.x])
    let part_y = Number(part[keys_part_size.y])
    let part_z = Number(part.z)
    let part_count = Number(part.count);
    let value_detail_count = Number(goods_service?.count) || 0
    let result = {
        value_detail: trimToFiveDecimals(value_detail_count),
        value: trimToFiveDecimals(value_detail_count * Number(product_count)), is_edit: true
    };
    if (is_perimetr) {
        let value_detail = trimToFiveDecimals((part_x / 1000 + part_y / 1000) * 2);
        result = {
            value_detail: value_detail,
            value: trimToFiveDecimals((value_detail * part_count) * Number(product_count)),
            is_edit: false
        }
    }
    if (is_volume) {
        let value_detail = trimToFiveDecimals((part_x * part_y * part_z) / 1000 / 1000 / 1000);
        result = {
            value_detail: value_detail,
            value: trimToFiveDecimals((value_detail * part_count) * Number(product_count)),
            is_edit: false
        }
    }
    if (is_square) {
        let value_detail = trimToFiveDecimals((part_x * part_y) / 1000 / 1000);

        result = {
            value_detail: value_detail,
            value: trimToFiveDecimals((value_detail * part_count) * Number(product_count)),
            is_edit: false
        }
    }
    return result
}
/**
 * Просчет кол-во дополнительных услуг для детали..
 * @param {object} part -
 * @param {string} sizeType  -
 * @param  {number} product_count  -
 * @returns  {array} goods_service_added_firm  -
 */
export const getCalculateAdditionalGoodsServiceOfPart = (part, sizeType, product_count) => {
    let goods_service_added_of_part = part?.goods_service_added_firm || [];
    if (goods_service_added_of_part?.length) {
        return goods_service_added_of_part?.reduce((acc, e) => {
            let result_count = getGoodsServicePartCountCalculateWithType(e, part, sizeType, product_count);
            let data = {
                ...e,
                count: result_count?.value,
                value_detail: result_count?.value_detail
            }
            acc.push(data)
            return acc
        }, []);
    }
    return []
}
/**
 * Сгруппировать услуги, которые применяются в деталях и просуммировать кол-во.
 * @param {object} order -
 * @returns  {array} goods_service_added_firm  -
 */
export const getGroupCalculateAdditionalGoodsServiceOfParts = (order, respone_goods = null) => {
    try {
        let parts = order?.part;
        if (!parts?.length) return null
        let group = parts?.reduce((acc, part) => {
            let additional_goods = part?.goods_service_added_firm?.filter(item=> item?.id) || null;
            if (!additional_goods) return acc;
            additional_goods.forEach((item) => {
                let find_actual_data = respone_goods?.GoodsServiceForPartByFirm?.find(i => Number(i?.id) === Number(item?.id)) || item;
                let key = `item_${item.id}`
                if (acc.hasOwnProperty(key)) {
                    if (Array.isArray(acc[key]['part']) && !acc[key]?.['part']?.find(e => Number(e.id) === Number(part.id))) {
                        acc[key]['part'] = [...acc[key]['part'], {
                            id: part.id,
                            count: Number(item?.count),
                            value_detail: Number(item?.value_detail)

                        }]
                    }
                    acc[key]['value_detail'] = trimToFiveDecimals(Number(item?.value_detail) + Number(acc[key]['value_detail']))
                    acc[key]['count'] = trimToFiveDecimals(Number(item?.count) + Number(acc[key]['count']))
                } else {
                    acc = {
                        ...acc,
                        [key]: {
                            ...find_actual_data,
                            count: item?.count,
                            value_detail: item?.value_detail,
                            date: item?.date,
                            part: [{
                                id: part.id,
                                count: Number(item?.count),
                                value_detail: Number(item?.value_detail)

                            }]
                        }
                    }
                }
            })
            return acc
        }, {})
        return Object?.entries(group)?.map(([key, value]) => value)?.sort((a, b) => a?.id - b?.id)
    } catch (e) {
        console.log('e', e)
        return null
    }
}

/**
 * Сгруппировать детали в доп. услуги.
 * @param {object} order -
 * @returns  {array} goods_service_added_firm  -
 */

export const getGoodsServiceOrderCountCalculateWithType = (goods_service, part, products, sizeType) => {
    let keys_part_size = getDetailOptionSizesOfType(sizeType)
    let count_of_part = !!Number(goods_service.count_connected_to_count_of_part)
    let volume_of_offer = !!Number(goods_service.count_connected_to_volume_of_offer)

    let result = {value: 0, is_edit: true};
    if (count_of_part) {
        let count_of_part_values = part?.reduce((acc, item) => {
            let part_count = Number(item.count)
            let find_product_count = products?.find(prod => Number(prod?.id) === Number(item?.product))?.count || 1;
            return Number(acc) + (Number(part_count) * Number(find_product_count))
        }, 0)
        return {
            value: trimToFiveDecimals(count_of_part_values),
            is_edit: false
        }
    }
    if (volume_of_offer) {
        let count_of_part_values = part?.reduce((acc, item) => {
            let part_x = Number(item[keys_part_size.x])
            let part_y = Number(item[keys_part_size.y])
            let part_z = Number(item.z)
            let part_count = Number(item.count)
            let find_product_count = products?.find(prod => Number(prod?.id) === Number(item?.product))?.count || 1;
            let part_size = (part_x * part_y * part_z) / 1000 / 1000 / 1000

            let value_count = ((part_size * part_count) * Number(find_product_count))

            return Number(acc) + Number(value_count)
        }, 0)
        return {
            value: trimToFiveDecimals(count_of_part_values),
            is_edit: false
        }
    }
    return result
}

/**
 * Изм курса услуг, которые применяются в заказе .
 * @param {object} order -
 * @returns  {array} goods_service_added_firm  -
 */
export const getCurrencyCalculateAdditionalGoodsServiceOfOrder = (order, respone_goods = null) => {
    try {
        let goods_service_added_order_firm = order.goods_service_added_order_firm;
        if (!goods_service_added_order_firm?.length) return null
        return goods_service_added_order_firm?.map(item => {
            let find_actual_data = respone_goods?.GoodsServiceForOrderByFirm?.find(i => Number(i?.id) === Number(item?.id)) || item;
            return {
                ...item,
                price: find_actual_data?.price,
                currency: find_actual_data?.currency,
                currency_name: find_actual_data?.currency_name,
            }
        })
    } catch (e) {
        console.log('e', e.message)
        return null
    }
}


