import {
    INITIAL_DEFAULT_PART_EDGE_EDIT,
    PROCESSING_EDGE_KEY_ASSOCIATION
} from "../../store/constats/processing-edge-const";
import {_getItemID} from "../../store/reducers/OrderReducer";


export const initialProcessingEdgeEditProjectForPart = (part, production, processing_edge_point) => {
    let edge_edit_interface_on = production?.['production.edge_edit_on'] || 0;
    let production_edge_edit = {
        hand: production?.['production.edge_Edit_handles_on'] || 0,
        faska: production?.['production.edge_Edit_faska_on'] || 0,
        srez: production?.['production.edge_Edit_srez_on'] || 0,
        round: production?.['production.edge_Edit_rounding_on'] || 0,
    }
    let xnc_mill_width_production_min_w_for_paz = Number(production?.['production.xnc_mill_width'])
    let srez = {...part.srez}
    let edit_edge = {...INITIAL_DEFAULT_PART_EDGE_EDIT};
    if (!!Number(edge_edit_interface_on) && part?.edge_edit) {
        for (const [key, is_on] of Object.entries(production_edge_edit)) {
            if (!!Number(is_on) && part.edge_edit?.hasOwnProperty(key) && part?.edge_edit?.[key]) {
                let associate_key = PROCESSING_EDGE_KEY_ASSOCIATION?.[key]
                let update_point_key = Object.entries(part?.edge_edit?.[key])
                    ?.reduce((acc, [side, edge]) => {
                        let find_actual = processing_edge_point?.[key]?.find(i => Number(i?.[associate_key?.edge_id_key]) === Number(edge?.[associate_key?.edge_id_key]) && i?.hasOwnProperty('enabled'))
                        let additional_update_edge = {}
                        if (edge?.hasOwnProperty('edge_edit_handle_painting') && Array.isArray(processing_edge_point?.hand_paint)) {
                            let edge_paint_id = edge?.edge_edit_handle_painting?.edge_edit_handle_painting_id
                            let paint_find = processing_edge_point?.hand_paint?.find(paint => Number(paint?.edge_edit_handle_painting_id) === Number(edge_paint_id))
                            if (paint_find) {
                                additional_update_edge = {
                                    edge_edit_handle_painting: paint_find
                                }
                            }
                        }
                        if (find_actual) {
                            // let update_option = {}
                            return {
                                ...acc, [side]: {
                                    ...find_actual,
                                    ...edge,
                                    enabled: find_actual?.enabled,
                                    two_sides_near_for_using: find_actual?.two_sides_near_for_using,
                                    ...additional_update_edge
                                }
                            }
                        }
                        return {
                            ...acc, [side]: edge
                        }
                    }, {})
                edit_edge = {
                    ...edit_edge,
                    [key]: update_point_key
                }
            } else {
                edit_edge = {
                    ...edit_edge,
                    [key]: null
                }
            }
        }
    }
    //получить все срезы
    let edit_edge_hand_find_srez_by_side = edit_edge?.hand ? Object.entries(edit_edge?.hand)?.reduce((acc, [key_side, hand]) => {
        let is_has_srez_operation = hand?.hasOwnProperty('srez_torca_grad') && !!Number(hand?.srez_torca_grad);
        if (is_has_srez_operation) {
            return {
                ...acc, [key_side]: {...hand, otstup_from_base: 0}
            }
        }
        return acc
    }, {}) : {};

    let srez_operations = {...edit_edge_hand_find_srez_by_side, ...edit_edge?.srez ?? {}}
    if (Object.keys(srez_operations)?.length) {
        //синхронизация part?.srez с edge_edit.hand[srez] && edge_edit.srez
        for (const [key_side, part_srez_val] of Object.entries(part?.srez)) {
            let srez_value = srez_operations?.[key_side] &&
            (!srez_operations?.[key_side]?.hasOwnProperty('enabled')
                || (srez_operations?.[key_side]?.hasOwnProperty('enabled') && !!Number(srez_operations?.[key_side]?.enabled)))
                ? srez_operations?.[key_side] : null;
            let is_hand_srez = srez_value?.hasOwnProperty("srez_torca_grad") && !!Number(srez_value?.srez_torca_grad);
            let key_custom_processing = srez_value?.hasOwnProperty(PROCESSING_EDGE_KEY_ASSOCIATION.srez?.edge_id_key) ? PROCESSING_EDGE_KEY_ASSOCIATION.srez?.edge_id_key
                : srez_value?.hasOwnProperty(PROCESSING_EDGE_KEY_ASSOCIATION.hand?.edge_id_key) ? PROCESSING_EDGE_KEY_ASSOCIATION.hand?.edge_id_key : null;

            let payload = {
                db_id: srez_value?.band_db_id ?? null,
                otstup: srez_value?.otstup_from_base ?? null,
                ugol: srez_value?.srez_torca_grad ?? null
            }
            if (key_custom_processing) {
                payload = {
                    ...payload,
                    [key_custom_processing]: srez_value?.[key_custom_processing],
                }
            }
            srez = {
                ...srez, [key_side]: {
                    ...payload
                }
            }
        }
    } else {
        //установка для старых проектов до введения обратотки торцов инициализация срезов в edge_edit.srez
        let is_on_production_srez = !!Number(production_edge_edit?.srez);
        let srez_edit_adding = {}
        for (const [key_side, part_srez_val] of Object.entries(part?.srez)) {
            let is_on_production_srez = !!Number(production_edge_edit?.srez);
            let srez_value = is_on_production_srez ?
                Object.values(part_srez_val)?.filter(v => !!v && !v?.db_id)?.length >= 1
                    ? part_srez_val : null
                : null;
            let is_hand_srez = part_srez_val?.hasOwnProperty("edge_edit_handle_id")

            if (srez_value && !is_hand_srez) {
                srez_edit_adding = {
                    ...srez_edit_adding,
                    [key_side]: {
                        band_db_id: srez_value?.db_id ?? null,
                        otstup_from_base: srez_value?.otstup ?? null,
                        srez_torca_grad: srez_value?.ugol ?? null
                    }
                }
            }
        }

        edit_edge = {
            ...edit_edge, ['srez']: srez_edit_adding,
        }
    }
    //paz

    let operation_paz_finalized = Array.isArray(part?.operations?.paz) ? [...part?.operations?.paz] : [];
    if (edit_edge?.hand) {
        let key_hand = PROCESSING_EDGE_KEY_ASSOCIATION.hand.edge_id_key;
        let operation_paz_without_hand = operation_paz_finalized?.filter(e => {
            return !e?.hasOwnProperty(key_hand)
        })

        let hand_for_paz = Object.entries(edit_edge?.hand).reduce((acc, [side, edge]) => {
            let is_hand_add_paz = !!edge?.hasOwnProperty(key_hand) && !!Number(edge?.paz_w) && !!Number(edge?.paz_z);
            if (is_hand_add_paz) return [...acc, {...edge, side: side}]
            return acc
        }, []);

        let operation_paz_only_hand = operation_paz_finalized?.filter(e => {
            let is_enabled_hand_paz_by_side = hand_for_paz?.find(h => Number(h?.[key_hand]) === Number(e?.[key_hand]) && h?.side === e?.side && !!Number(e?.enabled));
            if (is_enabled_hand_paz_by_side) return e

        })
        let paz_result = []
        hand_for_paz?.forEach((hand) => {
            let is_enabled_hand_paz_by_side = operation_paz_only_hand?.find(h => Number(h?.[key_hand]) === Number(hand?.[key_hand]) && h?.side === hand?.side);
            if (!is_enabled_hand_paz_by_side) {
            }
            let paz_id = _getItemID([...operation_paz_only_hand, ...operation_paz_without_hand, ...paz_result]);
            paz_result.push({
                ...is_enabled_hand_paz_by_side,
                id: paz_id,
                [PROCESSING_EDGE_KEY_ASSOCIATION.hand.edge_id_key]: hand?.[PROCESSING_EDGE_KEY_ASSOCIATION.hand.edge_id_key],
                side: hand?.side,
                side_from: hand?.side,
                // side_code: "top",
                w: xnc_mill_width_production_min_w_for_paz,
                w_paz: hand?.paz_w,
                f_w: hand?.paz_w,
                z: hand?.paz_z,
                d: null,
                type_paz: 1,
                d1: hand?.paz_d_from_back,
                band_db_id: hand?.band_db_id ?? null,

            })
        })
        operation_paz_finalized = [...paz_result, ...operation_paz_without_hand]

    }
    if (!edit_edge?.hand) {
        operation_paz_finalized = part?.operations?.paz?.filter(paz => !paz?.hasOwnProperty(PROCESSING_EDGE_KEY_ASSOCIATION.hand?.edge_id_key));
    }

    //удалить все обработки которые enabled = 0
    let delete_all_enabled_edge_edit = Object.entries(edit_edge)?.reduce((acc, [key_type, edge_value_sides]) => {
        if (!edge_value_sides) return {...acc, [key_type]: edge_value_sides}
        let result_edge_by_side = Object.entries(edge_value_sides)?.reduce((acc_by_side, [side, edge]) => {
            let is_on_enabled = !edge?.hasOwnProperty('enabled') || edge?.hasOwnProperty('enabled') && !!Number(edge?.enabled);
            if (is_on_enabled) {
                return {
                    ...acc_by_side, [side]: edge
                }
            }
            return acc_by_side
        }, {});
        return {
            ...acc, [key_type]: result_edge_by_side
        }
    }, {});
    return {
        edge_edit: delete_all_enabled_edge_edit, srez: srez,
        operations: {
            ...part.operations, paz: operation_paz_finalized
        }
    }
}