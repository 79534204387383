import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    changeMaterialsProjectModalValue,
    changeWarningModalValue,
    onChangeConnectionBand
} from "../../store/reducers/ModalReducer";
import "./ProjectMaterials.css";
import CloseModal from "../../Icons/Actions/Close";
import ProjectMaterialsItem from "./ProjectMaterialsItem";
import {
    _getBandCode,
    deleteAllMaterialBandOrderDataAC,
    deleteBandOrderDataAC,
    deleteMaterialOrderDataAC,
    onChanePartCombineMaterial,
    orderChangePositionIndexMaterialOrBand
} from "../../store/reducers/OrderReducer";
import GoBackArrow from "../../Icons/Actions/GoBackArrow";
import {changeAllPartsMaterialThunk} from "../../store/PartThunks";
import {useLang} from "../../context/langProvider";
import {getMaterialProjectIsOpen} from "../../selectors/modal-selector";
import {IconDelete} from "../../Icons/IconDelete";
import {gaEvents} from "../../gaEvents";
import {getIsOrderStatusEdit} from "../../selectors/order-selector";
import ConnectionBandModal from "../Modals/ModalConnectionBand/ConnectionBandModal";
import {orderMaterialToggleIsBandConnected} from "../../store/actions/order_material";
import IconQuestion from "../../Icons/IconQuestion";
import {HELP_ARTICLE_CONSTANTS_IDS} from "../../store/constats/help_article_constants";
import {helpArticleGetByIdToggleModalThunk} from "../../store/thunks/help-article-thunk";
import {deleteMultiChoiceMaterialAndBandForOrder} from "../../store/thunks/order-thunk";

const ProjectMaterials = () => {
    const isOpen = useSelector(getMaterialProjectIsOpen);
    return isOpen ? <Component key={'project-material-component'}/> : null
};
const INITIAL_VALUE_CHOICE_MATERIAL_FOR_DELETE = {
    band: [], material: []
}
const Component = () => {
    const {getT, lang} = useLang();
    const dispatch = useDispatch();
    const materials = useSelector((state) => state.order.order.material);
    const bands = useSelector((state) => state.order.order.band);
    const part = useSelector((state) => state.order.order.part);
    const currency = useSelector((state) => state.order.order.currency);
    const isOrderStatusEdit = useSelector(getIsOrderStatusEdit)

    const ref = useRef(null);

    const [choiceMaterialForDelete, setChoiceMaterialForDelete] = useState(INITIAL_VALUE_CHOICE_MATERIAL_FOR_DELETE);

    const handlerToggleChoiceMaterialForDelete = ({id = null, type = "band"}) => (event) => {
        if (choiceMaterialForDelete.hasOwnProperty(type) && id) {
            let is_include_id = choiceMaterialForDelete[type].includes(Number(id));
            if (is_include_id) {
                setChoiceMaterialForDelete((prev) => ({
                    ...prev,
                    [type]: prev[type]?.filter(_id => Number(_id) !== Number(id))
                }))
                return;
            }
            setChoiceMaterialForDelete(prev => ({
                ...prev,
                [type]: Array.isArray(prev[type]) ? [...prev[type], Number(id)] : [id]
            }));
        }
        return
    }

    const getArrayChangePositionItem = (array, currentIndex, move = true) => {
        const copyArray = [...array];
        let removeElem = [];
        let nextIndex = 0;

        if (move) {
            nextIndex = currentIndex - 1;
            if (nextIndex < 0) {
                nextIndex = array.length - 1;
            }
        } else {
            nextIndex = currentIndex + 1;
            if (nextIndex > array.length - 1) {
                nextIndex = 0;
            }
        }

        removeElem = copyArray.splice(currentIndex, 1)[0];
        copyArray.splice(nextIndex, 0, removeElem);

        return [...copyArray];
    };

    const onChangePositionItem = (type = "material", currentIndex, move = true) => {
        switch (type) {
            case "material": {
                const newMaterial = getArrayChangePositionItem(materials, currentIndex, move);
                dispatch(orderChangePositionIndexMaterialOrBand("material", newMaterial));
                break;
            }
            case "band": {
                const newBand = getArrayChangePositionItem(bands, currentIndex, move);
                dispatch(orderChangePositionIndexMaterialOrBand("band", newBand));
                break;
            }
            default  :
                console.log("type is  undefined ");

        }
    };

    const materialBox = materials.filter(material => {
        for (let p of part) {
            if (p.box && Number(p.material) === Number(material.goods_id)) {
                return material;
            }
        }

    });
    const activeBands = bands?.filter(item => {
        return part.find(p => {
            for (let key in p.edge) {
                if (Number(p.edge[key].db_id) === Number(item.goods_id)) {

                    return item;
                }
            }
            for (let key in p.srez) {
                if (Number(p.srez[key].db_id) === Number(item.goods_id)) {
                    return item;

                }
            }
            for (let key in p.srez) {
                if (p.srez[key].db_id === item.goods_id) {
                    return item;

                }
            }
            for (let i = 0; i < p.operations.frez.length; i++) {
                if (Number(p.operations.frez[i].db_id) === Number(item.goods_id)) {
                    return item;

                }
            }
            for (let i = 0; i < p.operations.hem.length; i++) {
                if (Number(p.operations.hem[i].band) === Number(item.goods_id)) {
                    return item;

                }
            }
        });
    });
    const openWarningModalDelete = (type = "material", id) => {
        let payload = {
            isOpen: true, title: getT("Удалить"),
            message: type === 'all' ? getT("Будут удалены все материалы, кромки, детали, уверены?") : getT("Будут удалены все детали, уверены?"),
            onSuccessful: onDeletePosition(type, id)
        }
        dispatch(changeWarningModalValue(payload));

        if (type === "material") {
            dispatch(onChanePartCombineMaterial([], id));
        }

    };
    const onDeletePosition = (type, id) => () => {
        if (type === "all") {
            let data = [...materials, ...bands];
            gaEvents.customEvent('remove_from_cart', {
                items: data?.map(item => ({
                    item_name: item?.name, // Name or ID is required.
                    item_id: item?.goods_id,
                    price: item?.price,
                    currency: currency,
                    quantity: 1
                }))

            })
            dispatch(deleteAllMaterialBandOrderDataAC())
        } else if (type === "material") {
            gaEvents.customEvent('remove_from_cart', {
                items: materials?.filter(item => item.goods_id === id)?.map(item => ({
                    item_name: item?.name, // Name or ID is required.
                    item_id: item?.goods_id,
                    price: item?.price,
                    currency: currency,
                    quantity: 1
                }))

            })
            dispatch(deleteMaterialOrderDataAC(id));
        } else {
            gaEvents.customEvent('remove_from_cart', {
                items: bands?.filter(item => item.goods_id === id)?.map(item => ({
                    item_name: item?.name, // Name or ID is required.
                    item_id: item?.goods_id,
                    price: item?.price,
                    currency: currency,
                    quantity: 1
                }))

            })
            dispatch(deleteBandOrderDataAC(id));
        }
    };
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target) && !event.target.closest('.modal-bg')) {
                dispatch(changeMaterialsProjectModalValue("isOpen", false));
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
    const onToggleConnectionBand = (connected_band) => (e) => {
        dispatch(onChangeConnectionBand({isOpen: true, connectionBands: connected_band}));
    };
    const onToggleBandsProject = (material) => (event) => {
        dispatch(orderMaterialToggleIsBandConnected({material}));
        dispatch(onChangeConnectionBand({isOpen: false, connectionBands: []}));

    }
    const handlerHelpArticle = (id) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(helpArticleGetByIdToggleModalThunk(id))
    }
    const handlerDeleteChoiceMaterial = (e)=>{
        dispatch(deleteMultiChoiceMaterialAndBandForOrder({material_ids: choiceMaterialForDelete.material, band_ids:choiceMaterialForDelete.band}));
        setChoiceMaterialForDelete(INITIAL_VALUE_CHOICE_MATERIAL_FOR_DELETE)
    }
    return <div className={"project_materials_main_container"} ref={ref}>
        <div className="project_materials_header">
            <GoBackArrow onClick={() => dispatch(changeMaterialsProjectModalValue("isOpen", false))}
                         isBlack={true}/>
            <h3 className={"project_materials_header_title"}>{getT("Материалы в проекте")}
                <IconQuestion onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.material_project)}
                              style={{marginLeft: '6px'}}/>

            </h3>
            {!!(materials?.length || bands?.length) && isOrderStatusEdit &&
                <div className="" title={getT('Удалить все материалы и кромки')}
                     style={{marginLeft: '8px', cursor: "pointer"}} onClick={() => openWarningModalDelete('all')}>
                    <IconDelete cName={'remove-all'}/>
                </div>}


            <CloseModal onClick={() => dispatch(changeMaterialsProjectModalValue("isOpen", false))} isBlack={true}/>
        </div>

        {!!(choiceMaterialForDelete?.band?.length || choiceMaterialForDelete?.material?.length )&& <div className="">
            <button className="btn-delete btn-small " type="button" onClick={handlerDeleteChoiceMaterial}>{getT("Удалить выбранные материалы")}</button>
        </div>}

        <div className="project_materials_content">
            {materials.map((e, index) => {
                let isDisabledDelete = materialBox.filter((m) => {
                    return Number(m.goods_id) === Number(e.goods_id);
                });
                const connected_bands = e?.connected_band?.filter(band => !bands?.find(item => Number(item.id) === Number(band.id)));
                return (<ProjectMaterialsItem
                        typeItem={"material"} lengthArray={materials.length - 1}
                        onChangePositionItem={onChangePositionItem}
                        index={index}
                        getT={getT}
                        onToggleBandsProject={onToggleBandsProject(e)}
                        onToggleConnectionBand={onToggleConnectionBand(connected_bands)}
                        lang={lang}
                        connected_bands={connected_bands}
                        isDisabledDelete={isDisabledDelete.length > 0 ? true : false}
                        handlerToggleChoiceMaterialForDelete={handlerToggleChoiceMaterialForDelete({id: e.id, type: 'material'})}
                        is_choice_item={choiceMaterialForDelete['material'].includes(Number(e.id))}
                        item={e}
                        isOrderStatusEdit={isOrderStatusEdit}
                        type={getT("Материал")}
                        changeMaterialSelectChildren={isOrderStatusEdit && <>
                            <div className="form-field">
                                <label className="label" htmlFor="producer">
                                    {getT("Заменить материал")}:
                                </label>
                                <select
                                    style={{width: "100%"}}
                                    className={""}
                                    value={e.type}
                                    defaultValue={e.type}
                                    onChange={(event) => {
                                        dispatch(changeAllPartsMaterialThunk(e.goods_id, event.target.value));
                                        event.target.value = "";
                                    }}
                                >
                                    <option value={""} key={0}>{getT("Выбрать...")}</option>
                                    {materials.map(m => {
                                        return <option value={m.goods_id}
                                                       disabled={Number(e.goods_id) === Number(m.goods_id)}
                                                       key={m.goods_id}>
                                            {m.hasOwnProperty("translate") && m["translate"][localStorage.getItem("lang")] ? m["translate"][localStorage.getItem("lang")] : m.name}</option>;

                                    })}
                                </select>
                            </div>
                        </>}
                        handlerDelete={() => {
                            openWarningModalDelete("material", e.goods_id);
                        }}/>
                );
            })}
            {bands.map((e, index) => <ProjectMaterialsItem lengthArray={bands.length - 1}
                                                           isOrderStatusEdit={isOrderStatusEdit}
                                                           typeItem={"band"}
                                                           onChangePositionItem={onChangePositionItem}
                                                           index={index}
                                                           lang={lang}
                                                           getT={getT}
                                                           isDisabledDelete={false}
                                                           bandSelect={bands}
                                                           activeBands={activeBands} item={e} type={getT("Кромка")}
                                                           handlerDelete={() => openWarningModalDelete("band", e.goods_id)}
                                                           bandCode={_getBandCode(e.goods_id, bands)}
                                                           is_choice_item={choiceMaterialForDelete['band'].includes(Number(e.id))}
                                                           handlerToggleChoiceMaterialForDelete={handlerToggleChoiceMaterialForDelete({id: e.id, type: 'band'})}

                />
            )}
        </div>

        <ConnectionBandModal key={'ConnectionBandModal'}/>
    </div>
}

export default ProjectMaterials;
