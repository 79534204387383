import ListPlane from "./ListPlane";
import React from "react";
import Edit from "./Edit";
import Create from "./Create";

const PlaneModal = () => {
    return <>
        <ListPlane/>
        <Edit/>
        <Create/>
    </>
}
export default PlaneModal;