import React from "react";
import MainApi from "./MainApi";
import axios from "axios";
import { blankHtml } from "../printHtml";


class ApiGiblab extends MainApi {

  //TODO: ссылка под замену (АПИ)

  constructor() {
    super();
    this._baseUrl += "/giblab";
  }

  // _baseUrl = 'https://test2-api.ifurn.pro/api/giblab';
  // _baseUrl = 'https://test-api.ifurn.pro/api/giblab';
  // _baseUrl = 'https://api.ifurn.pro/api/giblab';
  // _baseUrl = 'http://api-local.ifurn.pro/api/giblab';

  // _giblabApiLocal = 'https://gibserv.ifurn.pro';
  // _giblabApiMain = 'https://service.giblab.com';

  _giblabApiLocal = "https://newgo.ifurn.pro/api/gl/";
  _giblabApiMain = "https://newgo.ifurn.pro/api/gl2/";
  _withCredentials = "include";

  _checkApiDomain = async () => {
    const domain = (await new Promise((resolve, reject) => {

        let failed = 0;

        axios({
          url:this._giblabApiLocal,
          method: 'get',
          timeout: 2000,
        }).then(resolve, (error) => {
          failed++;
          if (failed === 2) {
            reject(error);
          }
          if(error) return reject(error);

        })

      })
    );
    return domain;
  };

  async fetchWithTimeout(url, options = {}) {
    const {
      timeout = 60,
      method = "GET",
      data = {},
      headers = {},
      resType = "json",
      isReturnError = false
    } = options;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const response = await this._send(url, method, data, headers, resType, isReturnError, {
      signal: controller.signal
    });

    clearTimeout(id);

    return response;
  }

  _getXml = async (order) => {
    const requestXml = await this._send(
      `/`,
      "POST",
      {
        order: order,
        action: "react_optimize_back_xml",
        base64: "0",
        cards_checked: []
      },
      {},
      "json"
    );
    if (requestXml && requestXml.message) {
      return { xml: requestXml.message, red_res: requestXml.red_res };
    } else {
      return false;
    }
  };


  _sendGiblab = async (domain, xml) => {

      return await axios.post(domain.config.url, xml, {
        timeout: 120000,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/octet-stream; charset=UTF-8",
          action: "C4F6336B"
        },
        withCredentials: true,
        validateStatus: (status) => {
          return status < 500; // Resolve only if the status code is less than 500
        }
      }).then(requestGiblab => {

        if (requestGiblab.error_api) {
          this._errors = requestGiblab.error_api;
          return false;
        }
        return Promise.resolve(requestGiblab.data);
      }).catch(e => {
        return Promise.resolve({ timout: true });
      });

  }
  ;


  getCards = async (resultGiblab, order = {}) => {
    const requestCards = await this._send(
      `/`,
      "POST",
      {
        action: "file_optimized_from_giblab_cards",
        message: resultGiblab,
        date: [],
        order: order
      },
      {
        "Content-Type": "application/octet-stream; charset=UTF-8"
      },
      "json"
    );

    const cards = requestCards;

    if (cards && cards.response && cards.response.files && cards.response.files[1]) {
      window.open(cards.response.files[1].link);
    } else {
      return false;
    }


  };

  getReport = async (resultGiblab, order = {}) => {
    const report = await this._send(
      `/`,
      "POST",
      {
        action: "file_optimized_from_giblab_report",
        message: resultGiblab,
        order: order
      },
      {
        "Content-Type": "application/octet-stream; charset=UTF-8"
      },
      "json"
    );

    if (report && report.response && report.response.files && report.response.files[1]) {
      window.open(report.response.files[1].link);
    } else {
      return false;
    }

  };
  BLANK_ACTION_TYPES = {
    "blank_html": "file_optimized_from_giblab_blank_ifp_html",
    "blank": "file_optimized_from_giblab_blank_ifp"
  };

  getBlank = async (resultGiblab, order = {}, type = "blank") => {
    const isHtml = type === "blank_html";
    const responseType = isHtml ? "text" : "json";
    // ------------
    // const printHtml = new PrintHtml();

    // ------------
    const blank = await this._send(
      `/`,
      "POST",
      {
        message: resultGiblab,
        order: order,
        action: this.BLANK_ACTION_TYPES[type]
      },
      {
        "Content-Type": "application/octet-stream; charset=UTF-8"
      },
      responseType
    );
    if (isHtml && blank) {
      const title = `№ ${order?.id}`;
      blankHtml.renderBase64ToHtmlWindow(blank, title, order);
    } else if (blank && blank.response && blank.response.res && blank.response.res.link) {
      window.open(blank.response.res.link);
    }
    // if(blank){
    //   return  blank
    // }
    else {
      return false;
    }

  };


  getCalc = async (resultGiblab, order) => {
    const calc = await this._send(
      `/`,
      "POST",
      {
        action: "file_optimized_from_giblab_calc",
        message: resultGiblab,
        order: order
      },
      {
        "Content-Type": "application/octet-stream; charset=UTF-8"
      },
      "json"
    );
    if (calc && calc.order && calc.order.order) {
      return calc.order.order;
    } else if (calc.api_error) {
      return { error: this._getErrorsMessage(calc.api_error) };
    } else {
      return false;
    }


  };
  getCutting = async (resultGiblab, order, isReturnError = false) => {
    const calc = await this._send(
      `/`,
      "POST",
      {
        action: "file_optimized_from_service",
        message: resultGiblab,
        order: order
      },
      {
        "Content-Type": "application/octet-stream; charset=UTF-8"
      },
      "json",
      isReturnError
    );

    if (calc && calc.order && calc.order) {
      return calc.order;
    }else if(calc?.message){
      return { error: calc?.message };
    }
    else if (calc.api_error) {
      return { error: this._getErrorsMessage(calc.api_error) };
    } else {
      return false;
    }


  };

  _Service = async (xml) => {

    return await axios.post(this._giblabApiMain, xml, {
      timeout: 900000,
      headers: {
        "Access-Control-Allow-Orgin": "*",
        "Content-Type": "application/octet-stream; charset=UTF-8",
        action: "C4F6336B"
      }
    }).then(requestGiblab => {

      if (requestGiblab.error_api) {
        this._errors = requestGiblab.error_api;
        return false;
      }
      return requestGiblab.data;
    }).catch(e => {
      return Promise.reject(e);
    });


  };

  _getErrorsMessage(errors) {
    if (!errors) {
      return false;
    }

    let errorText = "";
    errors.forEach(e => {

      if (e.code && Number(e.code) === 403) {
        for (var attr in localStorage) {
          if (attr != "lang") {
            // alert(localStorage[attr]);
            localStorage.removeItem(attr);
          }
        }
        // localStorage.clear();
        window.location.href = "/login";
      }
      if (typeof e.error === "string") {
        errorText += e.error + ",";
      } else {
        for (var key in e.error) {
          errorText += e.error[key] + ",";
        }
      }

    });

    return errorText;
  }

  _getErrors() {
    if (!this._errors) {
      return false;
    }
    let errorText = "";
    this._errors.api_error.forEach(e => {

      if (e.code && Number(e.code) === 403) {
        for (var attr in localStorage) {
          if (attr != "lang") {
            // alert(localStorage[attr]);
            localStorage.removeItem(attr);
          }
        }
        // localStorage.clear();
        window.location.href = "/login";
      }
      if (typeof e.error === "string") {
        errorText += e.error + ",";
      } else {
        for (var key in e.error) {
          errorText += e.error[key] + ",";
        }
      }

    });

    this._errors = null;

    return errorText;
  }

  _getPartLabel = async (order) => {
    const labels = await this._send(
      `/`,
      "POST",
      {
        action: "labels",
        order: order
      },
      {
        "Content-Type": "application/octet-stream; charset=UTF-8"
      },
      "json"
    );
    if (labels && labels.response) {
      return {
        base: labels.response.svg,
        svg: labels.response.svg64
      };
    } else {
      return false;
    }
  };

  _fetchIfurnLables = async (orderId, part_id) => {
    const res = await fetch(`${this._baseUrl}/${orderId}/${part_id}`, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      redirect: "follow",
      referrerPolicy: "no-referrer"
    });
    if (res.ok) {
      return await res.text();
    } else {
      return false;
    }
  };


}

export default ApiGiblab;