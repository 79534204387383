import React from "react";
import MaskedInput from "react-maskedinput";
import {maskPhoneCountry, SELECT_OPTIONS_PHONE} from "../constants";

export const listCountryCode = {
    excludeRegions: (regions, excludeCountry = 'ru', production = 0)=> {
        return Object.values(regions).filter(e=> {
                if(!((e.id === excludeCountry) && (Number(production) === 1))){
                    return e;
                }
            }).map(e => {
                return <option key={e.id} value={e.id}>{e.label}</option>;
            })
    },

    getMask : (regions, country='')=>{
       return regions.hasOwnProperty(country) ? regions[country].mask : '(111) 111-11-11';
    },
    getCode: (regions, country='', productionCode='')=>{
        return  regions.hasOwnProperty(country) ? regions[country].code : productionCode
    }
}

export const  parsePhoneNumber=(phoneNumber)=> {
    for (const country in maskPhoneCountry) {
        const countryInfo = maskPhoneCountry[country];
        const code = country;


        if (phoneNumber.startsWith(code)) {
            const phoneNumberWithoutCode = phoneNumber.slice(code.length);
            return {
                countryCode: code,
                phoneNumberWithoutCode: phoneNumberWithoutCode.trim(),
                mask:  maskPhoneCountry[code]
            };
        }
    }

    // If no matching country code is found, return the original phone number
    return {
        countryCode: '+38 ',
        phoneNumberWithoutCode: phoneNumber.trim(),
        mask: maskPhoneCountry['+38 ']
    };
}
export const findKeyByCodePhone = (codeToFind, options)=>{
        for (const key in options) {
            if (options.hasOwnProperty(key)) {
                if (options[key].code?.trim() === codeToFind?.trim()) {
                    return key;
                }
            }
        }
        return null;
}
export const compareAndFindCodePhoneByKey = (codeToFind, options)=>{
    for (const key in options) {
        if (options.hasOwnProperty(key)) {
            if (key?.trim() === codeToFind?.trim()) {
                return key;
            }
        }
    }
    return null;
}
export function validatePhoneNumber(countryCode, phoneNumber) {
    // Получаем данные по стране из SELECT_OPTIONS
    const countryData = maskPhoneCountry[countryCode];

    // Если страны с таким кодом нет, считаем номер некорректным
    if (!countryData) {
        return false;
    }

    // Формируем регулярное выражение на основе маски
    const regexPattern = countryData
        .replace(/\(/g, "\\(")
        .replace(/\)/g, "\\)")
        .replace(/-/g, "\\-")
        .replace(/\+/g, "\\+")
        .replace(/9/g, "\\d");

    const regex = new RegExp("^" + regexPattern + "$");

    // Проверяем соответствие номера маске
    return regex.test(phoneNumber);
}