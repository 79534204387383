class TemplateInput {

    constructor(input, isFrez, part = null) {
        this.id = input.xnc_template_symbol;
        this.name = input.name;
        this.type = input.type;
        this.max = 0;
        this.min = 0;
        this.symbol = input.symbol;
        this.options = [];
        this.accepted = [];
        this.isFrez = isFrez;
        this.part = part;
        this.input = input;
    }

    _getType() {
        switch (this.input.type) {
            case 'dig':
                this.type = 'number';
                break;
            case 'str':
                this.type = 'text';
                break;
            case 'bool':
                this.type = 'checkbox';
                break;
            case 'select':
                this.type = 'select';
                break;
        }
    }

    _getRanges() {
        try {
            this.min = eval(this._getPartSymbolResult(this.input.min_value_result))
            this.max =  eval(this._getPartSymbolResult(this.input.max_value_result))
        } catch (e) {
            this.min = this.part ? this._getPartSymbolResult(this.input.min_value_result) : this.input.min_value_result;
            this.max = this.part ? this._getPartSymbolResult(this.input.max_value_result) : this.input.max_value_result;
        }
    }

    _getOptions() {
        this.options = this.input.select_array.map(e => {
            return {
                value: e.id,
                label: e.text,
                image: e.pic,
                image_big: e.hasOwnProperty('pic_big') ? e.pic_big : null
            }
        })
    }

    _getAccepted = (input, part) => {
        this.accepted = this.input.restriction_by_select ? this.input.restriction_by_select.map(e => {

            var new_max = 0;
            try {
                new_max = eval(this._getPartSymbolResult(e.max_value))
            } catch (e) {
                new_max = eval(this._getPartSymbolResult(e.max_value))
            }

            e.max_value = new_max ? new_max : 0;

            return e;
        }) : []
    }

    _getPartSymbolResult = (symbol) => {
        return symbol && symbol.replace('detail.x2', this.part.x2 || this.part.x || this.part?.x1)
            .replace('detail.x1', this.part.x1 || this.part.x || this.part.x2)
            .replace('detail.x', this.part.x || this.part.x1 || this.part.x2)
            .replace('detail.y2', this.part.y2 || this.part.y || this.part.y1)
            .replace('detail.y1', this.part.y1 || this.part.y || this.part.y2)
            .replace('detail.y', this.part.y || this.part.y1 || this.part.y2)
            .replace('detail.z', this.part.z)
            .replace('detail.small_side', (Number(this.part.x1) > Number(this.part.y1) ? this.part.y1 : this.part.x1))
    }

    transform() {

        this._getType();

        if (this.type !== 'select') this._getRanges();
        if (this.type === 'select') this._getOptions();
        if (this.type === 'number') this._getAccepted();

        return {
            id: this.id,
            name: this.name,
            type: this.type,
            max: this.max,
            min: this.min,
            symbol: this.symbol,
            options: this.options,
            accepted: this.accepted,
            isFrez: this.isFrez,
        }
    }


}

export default TemplateInput;