import React from 'react';
import {connect} from "react-redux";
import Header from "./Header";
import {
    changeOrderTitleAC,
    saveOrderProjectDataThunk,
    saveOrderProjectFileDataThunk,
    saveOrderProjectVersionDataThunk
} from "../../store/reducers/OrderReducer";
import ParametersContext from '../../context/parametrs.js';
import {
    changeAddPartModalValue,
    changeMaterialsModalValue,
    changeUploadProjectModalValue
} from "../../store/reducers/ModalReducer";
import {sendGiblabOrderProjectThunk} from "../../store/reducers/CommonReducer";
import {LS_FIRM} from "../../constants";
import {isAccessEdit} from "../../pages/SettingsFirm/help";
import {materialSelectedFilterTogglePageAC} from "../../store/actions/material-selected-filter-actions";

class HeaderContainer extends React.Component {

    static contextType = ParametersContext;

    constructor(props) {
        super(props);
        this.saveProjectFile = this.saveProjectFile.bind(this);
        this.sendGiblab = this.sendGiblab.bind(this);
        this.state = {};
    }

    componentDidMount() {

    }

    saveProjectFile() {
        this.props.saveProjectFile(this.props.order);
    }

    sendGiblab() {
        this.props.sendGiblab(this.props.order, this.context.version);
    }

    render() {
        return <>
            <Header activeTab={this.props.activeTab}
                    onTabChange={this.props.onTabChange}
                    changeChecboxStatus={this.props.changeChecboxStatus}
                    title={this.props.title}
                    changeTitle={this.props.changeTitle}
                    saveProject={this.props.saveProject}
                    saveProjectVersion={this.props.saveProjectVersion}
                    saveProjectFile={this.saveProjectFile}
                    openUploadProject={this.props.openUploadProject}
                    sendGiblab={this.sendGiblab}
                    toggleMaterialsModal={this.props.toggleMaterialsModal}
                    toggleAddPartModal={this.props.toggleAddPartModal}
            />
        </>
    }
}

let mapStateToProps = (state) => {
    return {
        title: state.order.order.title,
        user: state.auth.user,
        order: state.order.order
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        changeTitle: (title) => dispatch(changeOrderTitleAC(title)),
        saveProject: (withSet = true, memoryDT = false, call = false) => dispatch(saveOrderProjectDataThunk(withSet, memoryDT, call)),
        saveProjectVersion: (cbShopping = null) => dispatch(saveOrderProjectVersionDataThunk(cbShopping)),
        saveProjectFile: (order) => dispatch(saveOrderProjectFileDataThunk(order)),
        openUploadProject: () => dispatch(dispatch(changeUploadProjectModalValue('isOpen', true))),
        sendGiblab: (order, version) => dispatch(sendGiblabOrderProjectThunk(order, version)),
        toggleMaterialsModal: (status) => {
            let firm = localStorage.getItem(LS_FIRM);
            if(isAccessEdit(firm)) {
                dispatch(changeMaterialsModalValue('isOpen', status));
                return
            }
            dispatch(materialSelectedFilterTogglePageAC({isOpen: status}));
            // dispatch(changeMaterialsModalValue('isOpen', status))
        },
        toggleAddPartModal: (status) => dispatch(changeAddPartModalValue('isOpen', status))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
