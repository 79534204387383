import React from 'react';

const SubMemu = ({getT, openHandlerCutting, openHandlerCuttingCards}) => {
    return (
        <div className="vertical-open">
            <div className={'vertical-open-item'} onClick={openHandlerCutting}><span>{getT('Раскрой')}</span></div>
            <div className={'vertical-open-item'} onClick={openHandlerCuttingCards}><span>{getT('Раскрой с картами')}</span></div>

        </div>
    );
};

export default SubMemu;