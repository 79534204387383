import React from 'react';
import InputForm from "../../../../../../../components/InputFields/InputForm/InputForm";
import {useLang} from "../../../../../../../context/langProvider";
import {useDispatch} from "react-redux";
import {useFormikContextProviderRegister} from "../../../../../SignUp";
import FiledPhoneMask from "../../../../../../../components/InputFields/FiledPhoneMask";
import {changeAlertStatusAC} from "../../../../../../../store/reducers/CommonReducer";
import {authThunkAccountAccess} from "../../../../../../../store/reducers/AuthReducer";

const StepLookup = ({onSwitchTab, handleNext, setting_phone_code_country = null}) => {
    const {getT} = useLang()
    const dispatch = useDispatch();
    const {values, handleChange, setFieldValue, validateForm} = useFormikContextProviderRegister();
    const handleValidate = () => {
        validateForm().then(async (errors) => {
            console.log(errors);
            // Handle the validation errors
            if (!Object.keys(errors).includes('email') && !Object.keys(errors).includes('phone')) {
                await onReqAccountAccess({send_password: 0})
                handleNext();

            } else if (Object.keys(errors).includes('email') || Object.keys(errors).includes('phone')) {
                let error_msg = `${errors['phone'] || ''} ${errors['email'] || ''}`
                dispatch(changeAlertStatusAC(true, error_msg));
            }
        });
    };
    const onSwitchTabLogin = (login) => {
        onSwitchTab(true, login);
    }
    const onReqAccountAccess = async ({send_password = 0}) => {
        if (values.email) {
            await dispatch(authThunkAccountAccess(
                {login: values.email, send_password: send_password}, null, () => onSwitchTabLogin(values.email)));
            setFieldValue('password', '');
            setFieldValue('master_password', '');
            return;
        }
        if (values.phone) {
            await dispatch(authThunkAccountAccess(
                {login: values.phone, send_password: send_password}, null, () => onSwitchTabLogin(values.phone)));
            setFieldValue('password', '');
            setFieldValue('master_password', '');
        }
    }
    const onChangeTelHandler = (result) => {
        handleChange({
            target: {name: 'phone', value: result},
        });
    }
    return (
        <div>
            <div className="mb-2">

                <InputForm label={getT('Введите email')}
                           id="email"
                           name="email"
                           type="email" value={values.email} onChange={handleChange}/>
            </div>
            <div className="mb-2">
                <FiledPhoneMask onChange={onChangeTelHandler}
                                label={getT('Введите номер телефона')}
                                value={values.phone} setting_code_country={setting_phone_code_country}/>

            </div>

            <button className={"btn m-0"} type={'button'} style={{margin: '0 auto'}}
                    disabled={!values.email && !values.phone}
                    onClick={handleValidate}>{getT("Отправить")}</button>


        </div>
    );
};

export default StepLookup;