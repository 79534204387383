export const PROCESSING_EDGE_KEY_ASSOCIATION = {
    round: {
        chart: 'R',
        edge_id_key: 'edge_edit_round_id',
        title: "Скругление торцов"
    },
    hand: {
        chart: 'H',
        edge_id_key: 'edge_edit_handle_id',
        title: "Врезные ручки"
    },
    srez: {
        chart: 'C',
        edge_id_key: 'edge_edit_srez_id',
        title: "Cрез торца",
        type_adding: {
            custom: {
                title: "Произвольный", value: 0
            },
            standart: {
                title: "Стандартный", value: 1
            }
        }
    },
    faska: {
        chart: 'F',
        edge_id_key: 'edge_edit_faska_id',
        title: "Снятие фаски"
    },

}
// export const PAZ_SIDE_CODE = {
//     t: 'top',
//     b: 'bottom',
//     l: 'left',
//     right: 'right'
// }
export const INITIAL_DEFAULT_PART_EDGE_EDIT = {
    round: null,
    hand: null,
    faska: null,
    srez: null
}