import React, {useMemo} from 'react';
import InputForm from "../../../../../../../components/InputFields/InputForm/InputForm";
import {useLang} from "../../../../../../../context/langProvider";
import {useDispatch} from "react-redux";
import {useFormikContextProviderRegister} from "../../../../../SignUp";
import {changeAlertStatusAC} from "../../../../../../../store/reducers/CommonReducer";

const StepUserData = ({handleNext}) => {
    const {getT} = useLang();
    const dispatch = useDispatch();
    const {values, handleChange, setFieldValue, validateForm} = useFormikContextProviderRegister();
    const handleValidate = () => {
        validateForm().then((errors) => {
            // Handle the validation errors
            if(!Object.keys(errors).includes('name') && !Object.keys(errors).includes('password') ){
                handleNext()
            }else if(Object.keys(errors).includes('name') || Object.keys(errors).includes('password')) {
                let error_msg = `${errors['name'] || ''} ${errors['password'] || ''}`
                dispatch(changeAlertStatusAC(true, error_msg));
            }
        });
    };
    const isDisabled = useMemo(()=>{
        let isPhone = values.phone && !values.email;
        if(isPhone){
            return !values.name || !values.password
        }

        return values.name?.length < 3
    }, [values])


    return (
        <div >
            <InputForm label={getT("Введите имя")}
                        id="name"

                        name="name"
                       cName={"mb-2"}
                        type="text" value={values.name} onChange={handleChange} required={true}/>

            {values.phone && !values.email &&
                <InputForm label={getT('Введите пароль')}
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$"
                             id="password"
                           cName={"mb-2"}
                            name="password"
                           required={true}
                            type="text" value={values.password} onChange={handleChange}/>
            }
            <button type={'button'} style={{margin: '0 auto'}} className={'btn'}
                    disabled={isDisabled}
                    onClick={handleValidate}>{getT("Далее")}</button>

        </div>
    );
};

export default StepUserData;