import React, {useEffect, useState} from "react";
import {useDetectClickOutside} from "react-detect-click-outside";
import {useDispatch} from "react-redux";
import {getGiblabDataThunk, getGiblablDataAPIThunk} from "../../store/reducers/CommonReducer";
import Print from "../../Icons/Actions/Print";
import {saveOrderProjectDataThunk} from "../../store/reducers/OrderReducer";
import {checkOrderOperationsExist} from "../../helpers/helpers";
import {LABELS_CUTTING_PRINT} from "../../store/reducers/CuttingChartReducer";
import useKeydownPress from "../../HOOK/useKeydownPress";
import {onChangeLabelsPrintGirdModal} from "../../store/reducers/ModalReducer";

const GlDropdown = ({ getT, order, cName='' }) => {
  const [isVisible, setVisible] = useState(false);
  const orderHaveOperations = checkOrderOperationsExist(order);
  const {isKeyPress, setKeyPress} = useKeydownPress({keyCode: 71})
  const ref = useDetectClickOutside({ onTriggered: () => setVisible(false) });

  const dispatch = useDispatch();
  const getGiblabReport = (type) => dispatch(getGiblabDataThunk(type));
  const getGiblabDataAPI = (action) => dispatch(getGiblablDataAPIThunk(action));
  // const onGetLables = (action) => {
  //   dispatch(getCuttingLabelsForPrint({ }));
  // };

  const onChangeValueModal = (values)=>{
    dispatch(onChangeLabelsPrintGirdModal(values))

  }
  useEffect(()=>{
  }, [isKeyPress]);
  if(!isKeyPress) return <></>

  return (
    <>
      {isVisible &&  <div className="shadow"></div>}

    <div ref={ref} className={`print_cutting_cards__container ${cName}`} >
      <span onClick={() => setVisible(!isVisible)} className={'gib-drop-btn'}>GL</span>
      <div className="">

        {isVisible && <>

          <div className="add prints-actions-container print_cutting_cards">
            <div className="prints-singles-actions-container">

              <div
                className={"singl-action-item"}
                onClick={() => {
                  // dispatch(saveOrderProjectDataThunk());
                  getGiblabReport("cards");
                }}
              >
                <Print />{" "}
                <span className={"singl-action-item-name"}>
                        {getT("Карты раскроя")}
              </span>
              </div>
              <div
                className={"singl-action-item"}
                onClick={() => {
                  // dispatch(saveOrderProjectDataThunk());
                  getGiblabReport("reports");
                }}
              >
                <Print />{" "}
                <span className={"singl-action-item-name"}>
                        {getT("Полный отчет")}
                      </span>
              </div>
              <div className={"singl-action-item singl-action-item-cutting-btn"} onClick={() => onChangeValueModal({isOpen: true, action:LABELS_CUTTING_PRINT.giblab})}>
                <span className={"cutting-preview__btn"}></span>
                {" "}
                <span className={"singl-action-item-name"}>
                        {getT("Этикетки GL")}
                      </span>
              </div>
              {!!orderHaveOperations && <div
                className={"singl-action-item"}
                onClick={() => {
                  // dispatch(saveOrderProjectDataThunk());
                  getGiblabDataAPI("xnc");
                }}
              >
                <Print />{" "}
                <span className={"singl-action-item-name"}>
                        {getT("Чертежи ЧПУ Giblab")}
                      </span>
              </div>}
            </div>
          </div>

        </>}
      </div>
    </div>
      </>
  );
};

export default GlDropdown;